/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Table from "../../../components/table";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    strong: "strong",
    code: "code",
    a: "a",
    h4: "h4",
    ul: "ul",
    li: "li",
    em: "em",
    pre: "pre",
    ol: "ol"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Project Overview"), "\n", React.createElement(_components.p, null, "My friends and I enjoy puzzle hunts and knowing lots of random trivia is usually helpful. This made me think of creating Triviago partly as a training tool, partly as a fun side project, and partly as a way to explore newer frontend technologies."), "\n", React.createElement(_components.p, null, "In the end, the game has 3 'game modes':"), "\n", React.createElement(Table, {
    headerRow: ["Feeling Lucky", "Normal Quiz", "Sudden Death"],
    rows: [["https://res.cloudinary.com/kiittsunne/video/upload/e_loop:10,br_3564k,c_scale,f_gif,h_600,q_100/v1665656903/2022-10-13_18.04.00_n3blah.gif", "https://res.cloudinary.com/kiittsunne/video/upload/e_loop:10,br_3564k,c_scale,f_gif,h_600,q_100/v1665656903/2022-10-13_18.03.55_yf0ttb.gif", "https://res.cloudinary.com/kiittsunne/video/upload/e_loop:10,br_3564k,c_scale,f_gif,h_600,q_100/v1665656903/2022-10-13_18.03.46_jonlba.gif"], ["Slot machine tiles represent themes and 12 questions from each unique theme will be fetched", "Choose multiple categories, set difficulty and limit number of questions", "Player gets 30 hard questions, 10s to answer each question and lose lives if wrong/ no answer"]]
  }), "\n", React.createElement("hr"), "\n", React.createElement(_components.h3, null, "Developer Installation & Build"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Start Vite Developer Server"), ": ", React.createElement(_components.code, null, "npm run dev")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Build Production"), ": ", React.createElement(_components.code, null, "npm run build")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "https://vitejs.dev/guide/static-deploy.html#github-pages"
  }, "Deployment Resources"))), "\n", React.createElement("hr"), "\n", " ", "\n", React.createElement(_components.h3, null, "Retrospective: Process Notes, Key Learning Points, Future Plans"), "\n", React.createElement(_components.h4, null, "Process notes:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "1. ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=bAJlYgeovlg"
  }, "WDS React/Typescript multiselect component tutorial"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Followed along and then rebuilt the component from memory/ by feel for this project"), "\n", React.createElement(_components.li, null, "Differences:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "WDS used Bootstrap, I rewrote css using Tailwind."), "\n", React.createElement(_components.li, null, "Data types also had to be redefined to accommodate api params submission"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "2. ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=F2JCjVSZlG0"
  }, "FreeCodeCamp React/Typescript quiz app"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Similar to WDS selector tutorial, I originally coded along. Built \"Normal Quiz\" section from memory/feel."), "\n", React.createElement(_components.li, null, "Differences:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "FCC used a different trivia api & styled-components."), "\n", React.createElement(_components.li, null, "I expanded on the base project with the SlotMachine and SuddenDeath quiz types."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h4, null, "Reverse engineering code:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "1. ", React.createElement(_components.a, {
    href: "https://medium.com/@victortoschi/how-to-create-a-slot-machine-animation-with-css-and-javascript-9073ab9db9ea"
  }, "Victor Toschi's vanilla DOM-Manipulation slot machine"))), "\n", React.createElement(_components.p, null, "I didn't read the Medium article, but focused on his ", React.createElement(_components.a, {
    href: "https://codepen.io/toschivictor/pen/JjNZjEj"
  }, "Codepen"), ". Took about 1h to fully understand and test what his JS logic was doing, then rewrote it in React."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Learning Points:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Got familiar with useRef: because my implementation still relies on CSS animations rather than state manipulation, could not rely on ", React.createElement(_components.code, null, "useState"), " to capture and send information to API function call. Used useRef instead to capture data from DOM without re-rendering."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Challenges: working with HTML DOM Object properties and Typescript was an interesting challenge. Previously without typechecking, I didn't think twice about doing something like the below. But with TS i was more mindful about optionally chaining properties."), "\n"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "// before:\nonTransitionEnd={() => {\n\n    const last = document.getElementById(\"doorOne\")?.lastChild.id;\n    // `.id` would throw err because it's not a guaranteed property\n\n    // setting useRef\n    tagInputs.current.push(`${last}`);\n}}\n\n//after:\nonTransitionEnd={() => {\n\n    const nodes = document.getElementById(\"doorOne\")?.children;\n\n    const last = nodes && nodes[nodes.length - 1]?.id;\n\n    // setting useRef\n    tagInputs.current.push(`${last}`);\n}}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Key Learning Points")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Practiced TypeScript in a React project"), "\n", React.createElement(_components.li, null, "Exposure to useRef use cases"), "\n", React.createElement(_components.li, null, "Exposure to TailwindCSS"), "\n", React.createElement(_components.li, null, "Learnt deployment process of Vite to github pages"), "\n", React.createElement(_components.li, null, "Learnt basic CI/CD with github workflow - auto update production build on git push"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Future Plans")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Integrate Google Analytics to track user behaviour and display interesting statistics (e.g. like Wordle's summary graph)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
